@keyframes slideInAndar {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInBahar {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-andar {
  animation: slideInAndar 0.8s ease-out;
}

.animate-bahar {
  animation: slideInBahar 0.8s ease-out;
}

@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-card {
  animation: slideUp 0.8s ease-out;
}
