@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "aviator-bolt-font";
	src: url("../public/assets/rocket/rbfont.ttf");
}
@font-face {
	font-family: "aviator-regular-font";
	src: url("../public/assets/rocket/rrfont.ttf");
}

@font-face {
	font-family: "FS Elliot Pro";
	src: url("../public/fonts/FSElliotPro-Bold.eot");
	src: url("../public/fonts/FSElliotPro-Bold.eot?#iefix")
			format("embedded-opentype"),
		url("../public/fonts/FSElliotPro-Bold.woff2") format("woff2"),
		url("../public/fonts/FSElliotPro-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "FS Elliot Pro";
	src: url("../public/fonts/FSElliotPro.eot");
	src: url("../public/fonts/FSElliotPro.eot?#iefix") format("embedded-opentype"),
		url("../public/fonts/FSElliotPro.woff2") format("woff2"),
		url("../public/fonts/FSElliotPro.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "FS Elliot Pro";
	src: url("../public/fonts/FSElliotPro-Light.eot");
	src: url("../public/fonts/FSElliotPro-Light.eot?#iefix")
			format("embedded-opentype"),
		url("../public/fonts/FSElliotPro-Light.woff2") format("woff2"),
		url("../public/fonts/FSElliotPro-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "FS Elliot Pro";
	src: url("../public/fonts/FSElliotPro-Italic.eot");
	src: url("../public/fonts/FSElliotPro-Italic.eot?#iefix")
			format("embedded-opentype"),
		url("../public/fonts/FSElliotPro-Italic.woff2") format("woff2"),
		url("../public/fonts/FSElliotPro-Italic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "FS Elliot Pro";
	src: url("../public/fonts/FSElliotPro-Thin.eot");
	src: url("../public/fonts/FSElliotPro-Thin.eot?#iefix")
			format("embedded-opentype"),
		url("../public/fonts/FSElliotPro-Thin.woff2") format("woff2"),
		url("../public/fonts/FSElliotPro-Thin.woff") format("woff");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

body {
	@apply leading-[normal] m-0 bg-dark-blue;
	font-family: "FS Elliot Pro", sans-serif !important;
}
*,
::before,
::after {
	border-width: 0;
}

.sub-header {
	position: sticky;
	top: -17px;
	margin-top: -24px;
	width: calc(100vw - -8px);
	margin-left: -24px;
	background: #2a324a;
}

.sub-header > div,
.sub-header > a {
	padding: 0 12px !important;
}

.aviator-sub-header {
	position: sticky;
	top: -17px;
	margin-top: -24px;
	width: calc(100vw - -8px);
	margin-left: -24px;
	background: rgb(23, 23, 22);
}

.aviator-sub-header > div,
.aviator-sub-header > a {
	padding: 0 12px !important;
}

@media screen and (min-width: 1024px) {
	.sub-header {
		position: sticky;
		top: -16px;
		margin-top: -16px;
		width: calc(100vw - 74px);
		margin-left: -16px;
		background: #2a324a;
	}

	.sub-header > div,
	.sub-header > a {
		padding: 0 24px !important;
	}
	.aviator-sub-header {
		position: sticky;
		top: -16px;
		margin-top: -16px;
		width: calc(100vw - 74px);
		margin-left: -16px;
		background: rgb(23, 23, 22);
	}

	.aviator-sub-header > div,
	.aviator-sub-header > a {
		padding: 0 24px !important;
	}
}

.Toastify__toast-container {
	width: 300px; /* Set your desired width here */
	position: fixed;
	top: 5%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.unselectable {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	margin: 0;
	padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}

.custom-table {
	border: 4px solid white;
}

.custom-table {
	border-collapse: collapse;
}

.custom-table th,
.custom-table td {
	text-align: center;
	border: 2px solid white;
	padding: 8px; /* Add padding for better spacing */
}

.custom-pill {
	background: #171716;
	font-size: 0.7rem;
}

.sky-color {
	color: rgb(52, 180, 255);
}

.indigo-color {
	color: rgb(145, 62, 248);
}

.high-color {
	color: rgb(192, 23, 180);
}
/* Customize styles as needed */
.custom-tabs {
	display: flex;
	overflow: hidden;
	max-width: fit-content;
	padding: 2px 0px;
	border-radius: 20px;
	background-color: #171716;
}

.custom-tab {
	text-align: center;
	padding: 2px 25px;
	color: white;
	border: 1px solid transparent;
	border-radius: 20px; /* Rounded pill shape */
	margin: 0 5px; /* Adjust margin as needed */
	font-size: small;
}

.active-tab {
	background-color: rgb(44, 45, 48);
	color: white;
}

.inactive-tab {
	background-color: black;
	color: #9ea0a3;
}

.input-group-rounded-pill {
	margin: 0 5px;
	background-color: black;
	box-shadow: none;
	border: none;
}

.input-group-rounded-pill .btn {
	color: white;
	border: solid 1px white;
	outline: none;
	width: 15px; /* Set the width */
	height: 15px; /* Set the height */
	padding: 0; /* Remove extra padding */
	display: flex;
	justify-content: center;
	align-items: center;
}

.input-group-rounded-pill .btn:focus,
.input-group-rounded-pill .btn:active {
	box-shadow: none;
}

.input-group-rounded-pill .form-control {
	color: white;
	border: none;
	outline: none;
	box-shadow: none;
}

.input-group-rounded-pill .form-control:focus,
.input-group-rounded-pill .form-control:active {
	box-shadow: none;
}

.custom-input-pill .btn:focus,
.custom-input-pill .btn:active {
	box-shadow: none;
}

.custom-input-pill .form-control {
	color: white;
	border: none;
	outline: none;
	box-shadow: none;
}

.custom-input-pill .form-control:focus,
.custom-input-pill .form-control:active {
	box-shadow: none;
}

.aviator-bet-btn {
	background: #28a909;
	border: solid 1px #b2f2a3;
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: rgb(178, 242, 163);
	border-right-color: rgb(178, 242, 163);
	border-bottom-color: rgb(178, 242, 163);
	border-left-color: rgb(178, 242, 163);
	background-color: #28a909 !important;
	box-shadow: inset 0 1px 1px #ffffff80;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.aviator-cancel-btn {
	border: solid 1px #ff7171;
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: rgb(255, 113, 113);
	border-right-color: rgb(255, 113, 113);
	border-bottom-color: rgb(255, 113, 113);
	border-left-color: rgb(255, 113, 113);
	background-color: #cb011a;
	box-shadow: inset 0 1px 1px #ffffff80;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.aviator-cashout-btn {
	border: solid 1px #ffbd71;
	border-top-width: 1px;
	border-right-width: 1px;
	border-bottom-width: 1px;
	border-left-width: 1px;
	border-top-style: solid;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-top-color: rgb(255, 189, 113);
	border-right-color: rgb(255, 189, 113);
	border-bottom-color: rgb(255, 189, 113);
	border-left-color: rgb(255, 189, 113);
	background-color: #d07206 !important;
	box-shadow: inset 0 1px 1px #ffffff80;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

canvas {
	border-radius: 20px; /* Adjust the radius as needed */
	border: hsla(0, 0%, 100%, 0.1) solid 1px;
	/* padding: 5px; */
	overflow: hidden;
}

#ludo-game canvas {
	border: none;
}
